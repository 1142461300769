/**
 * List clients.
 */
import { apiGet } from '@/util/api';
import Icon from '@/icons/Icon.vue';
import { saveAs } from 'file-saver';

export default {
  name: 'Reports',
  components: {
    Icon,
  },
  data() {
    return {
      showFilters: false,
      nullSessions: false,

      logs: [],
      month: 0,

      users: [],
      user: null,
      ip: null,
      startDate: (() => {
        // default show logs this month so far
        const date = new Date();
        date.setDate(1);
        return date;
      })(),
      endDate: new Date(),
      datesError: '',

      year: 0,
      refDate: new Date(),
      logsLoaded: false,
      loading: true,
      generatingCSV: false,
      logsTablePerPage: 10,
      logsTableCurrentPage: 1,
      logsTableFields: [
        {
          key: 'user.name',
          sortable: true,
          label: this.$t('reports_list_name'),
        },
        {
          key: 'ip',
          label: this.$t('reports_list_ip'),
        },
        {
          key: 'loginTime',
          sortable: true,
          label: this.$t('reports_list_session_start'),
        },
        {
          key: 'lastInteractTime',
          sortable: true,
          label: this.$t('reports_list_last_seen'),
        },
        {
          key: 'sessionLength',
          sortable: true,
          label: this.$t('reports_list_session_length'),
          formatter: this.sesionLengthFormatter,
        },
      ],
    };
  },
  computed: {
    monthOptions: function() {
      return this.$t('reports_months').map((text, value) => ({ text, value }));
    },
    yearOptions: function() {
      const thisYear = this.refDate.getFullYear();
      const years = Array(thisYear - 2019).fill(0); // = years since 2020 inclusive
      return years.map((y, i) => ({ text: 2020 + i, value: 2020 + i }));
    },
    filteredLogs() {
      this.logsTableCurrentPage = 1;
      return this.logs.filter(
        l =>
          (l.sessionLength > 0 || this.nullSessions) &&
          (!this.ip || l.ip === this.ip) &&
          (!this.user || l.user.userId === this.user),
      );
    },
    ips() {
      let ips = [];
      this.logs.forEach(l => {
        if (this.user === l.user.userId && ips.indexOf(l.ip) === -1) {
          ips.push(l.ip);
        }
      });
      return ips;
    },
  },
  async created() {
    // Get the list of logs from the API.
    try {
      this.month = this.refDate.getMonth();
      this.year = this.refDate.getFullYear();
      await this.refreshLogs();
    } catch (error) {
      this.$bvToast.toast(this.$t('reports_error_get_logs'), {
        title: this.$t('common_error_title'),
        variant: 'danger',
      });
    } finally {
      this.loading = false;
      this.logsLoaded = true;
    }
  },
  methods: {
    async refreshLogs() {
      if (!this.checkValidity()) return;

      this.loading = true;
      this.logsLoaded = false;

      this.users = [];
      const start = new Date(this.startDate);
      const end = new Date(this.endDate);
      this.logs = (
        await apiGet(`/log?start=${start.getTime()}&end=${end.getTime()}`)
      ).map(l => {
        if (!this.users.some(u => u.userId === l.user.userId)) {
          this.users.push(l.user);
        }
        return {
          ...l,
          sessionLength:
            Date.parse(l.lastInteractTime) - Date.parse(l.loginTime),
        };
      });

      this.users
        .sort(function(a, b) {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        })
        .unshift({ id: null, name: '' });

      this.loading = false;
      this.logsTableCurrentPage = 1;
      this.logsLoaded = true;
    },
    downloadCSV() {
      if (!this.checkValidity()) {
        this.$bvToast.toast(this.$t('reports_csv_validation_error'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
        return;
      }

      this.generatingCSV = true;
      try {
        let csv = `${this.$t('reports_list_name')},${this.$t(
          'reports_list_ip',
        )},${this.$t('reports_list_session_start')},${this.$t(
          'reports_list_last_seen',
        )},${this.$t('reports_list_session_length')}\r\n`;
        this.filteredLogs.forEach(log => {
          csv += `${log.user.name},${log.ip},${log.loginTime
            .replace('T', ' ')
            .replace('.000Z', '')},${log.lastInteractTime
            .replace('T', ' ')
            .replace('.000Z', '')},${this.sesionLengthFormatter(
            log.sessionLength,
          )}\r\n`;
        });
        const csvFile = new Blob([csv], { type: 'text/csv' });
        saveAs(csvFile, `UserReport_${this.month + 1}-${this.year}.csv`);
      } catch (e) {
        this.$bvToast.toast(this.$t('reports_error_get_csv'), {
          title: this.$t('common_error_title'),
          variant: 'danger',
        });
      } finally {
        this.generatingCSV = false;
      }
    },
    changeDate(isStart) {
      if (new Date(this.startDate) > new Date(this.endDate)) {
        if (isStart) this.endDate = this.startDate;
        else this.startDate = this.endDate;
      }

      this.refreshLogs();
    },
    checkValidity() {
      if (
        new Date(this.endDate) - new Date(this.startDate) >
        30 * 24 * 3600 * 1000
      ) {
        this.datesError = this.$t('reports_date_range_too_big');
        return;
      } else {
        this.datesError = '';
      }

      return !this.datesError;
    },
    sesionLengthFormatter(val) {
      if (typeof val != 'number') return 'n/a';
      if (val < 1) return '0 seconds';
      const days = Math.floor(val / 86400000);
      const hours = Math.floor((val % 86400000) / 3600000);
      const minutes = Math.floor(((val % 86400000) % 3600000) / 60000);
      const seconds = Math.round((((val % 86400000) % 3600000) % 60000) / 1000);
      let output = '';
      if (days > 0) output += `${days} days `;
      if (hours > 0) output += `${hours} hrs `;
      if (minutes > 0) output += `${minutes} mins `;
      if (seconds > 0) output += `${seconds} secs`;
      return output;
    },
  },
};
